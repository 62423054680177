<template>
  <div class="menu-body" v-if="$oidc.isAuthenticated &&
    $oidc.userProfile &&
    $oidc.userProfile.roles &&
    $oidc.userProfile.roles.length > 0
    ">
    <Loader v-if="showLoader" />
    <Breadcrumb />
    <div class="asmt-con">
      <div style="display: flex">
        <div class="principle-dd">
          <label for="pillar" class="form-label page-heading-dropdown lable-text">Pillar</label>
          <b-form-input class="form-control select-text select-text-pd text-class" v-model="pillarName" disabled>
          </b-form-input>
        </div>
        <div class="principle-dd" style="padding-left: 0.5rem">
          <label for="pillar" class="form-label page-heading-dropdown lable-text">Principle</label>
          <b-form-input class="form-control select-text select-text-pd text-class" v-model="principleName"
            disabled></b-form-input>
        </div>
      </div>

      <div class="level-con">
        <div class="level-border">
          <b-form-radio-group id="radio-group-1" v-model="targetLevel" name="radio-options"
            style="display: inline-flex; width: 100%">
            <div class="page-heading-tab level-info level-br live-level" style="border-radius: 4px 0 0 4px" id="Level0"
              :class="{ cursorNotAllowed: levelId != 0 }" :disabled="levelId != 0">
              <label class="container">
                Level 0
                <input type="radio" class="cursorpointer" name="some-radios" value="0" style="margin-right: 1rem" />

              </label>
            </div>
            <div class="page-heading-tab level-info level-br live-level" id="Level1"
              :class="{ cursorNotAllowed: levelId != 1 }" :disabled="levelId != 1">
              <label class="container">
                Level 1
                <input type="radio" class="cursorpointer" name="some-radios" value="1" style="margin-right: 1rem" />

              </label>
            </div>
            <div class="page-heading-tab level-info level-br live-level" id="Level2"
              :class="{ cursorNotAllowed: levelId != 2 }" :disabled="levelId != 2">
              <label class="container">
                Level 2
                <input id="level2" type="radio" class="cursorpointer" name="some-radios" value="2"
                  style="margin-right: 1rem" />

              </label>
            </div>
            <div class="page-heading-tab level-info level-br live-level" id="Level3"
              :class="{ cursorNotAllowed: levelId != 3 }" :disabled="levelId != 3">
              <label class="container">
                Level 3
                <input type="radio" class="cursorpointer" name="some-radios" value="3" style="margin-right: 1rem" />

              </label>
            </div>
            <div class="page-heading-tab level-info level-br live-level" id="Level4"
              :class="{ cursorNotAllowed: levelId != 4 }" :disabled="levelId != 4">
              <label class="container">
                Level 4
                <input type="radio" class="cursorpointer" name="some-radios" value="4" style="margin-right: 1rem" />

              </label>
            </div>
            <div class="page-heading-tab level-info live-level" id="Level5" :class="{ cursorNotAllowed: levelId != 5 }"
              :disabled="levelId != 5" style="border-radius: 0 4px 4px 0">
              <label class="container">
                Level 5
                <input type="radio" class="cursorpointer" name="some-radios" value="5" style="margin-right: 1rem" />
              </label>

            </div>
          </b-form-radio-group>
        </div>
      </div>
      <div class="prp-info">
        <div class="module-title prp-name">
          {{
            this.principleArr[0] +
            "." +
            targetLevel +
            " " +
            this.principleArr[1]
          }}
        </div>
        <div class="page-content-regular prp-name">
          <pre class="page-content-regular">{{ this.practiceDescription }}</pre>
        </div>
        <div class="module-title prp-name margin-top-1em">Guidelines</div>
        <div class="behavoir-des page-content-regular" id="guidelineDescription">
          {{ this.guidelineDesc }}
        </div>
      </div>
      <div class="attch-con">
        <div class="module-title prp-name margin-top-1em">Attachments</div>
        <div class="row attch-row">
          <ul class="col-3 uploaded-items attch-col page-content-regular">
            <li class="uploaded-items-li" v-for="(eachattachment, i) in attachmentUrls" :key="i">
              <span class="uploaded-file-name">
                <a class="li-doc-class" @click="downloadItem()" :download="eachattachment">{{
                  decodeURIComponent(
                    eachattachment
                      .split("/")
                    [eachattachment.split("/").length - 1].split("_")[0] +
                    "." +
                    eachattachment
                      .split("/")
                    [eachattachment.split("/").length - 1].split(".")[
                    eachattachment
                      .split("/")
                    [eachattachment.split("/").length - 1].split(".")
                      .length - 1
                    ]
                  )
                }}</a>
                <span>
                  <img alt="more" class="attch-icon" src="../../../assets/download 2.svg" />
                </span>
              </span>
            </li>
          </ul>
        </div>
      </div>
      <div>
        <div class="attch-con proposed-rec" :class="{ 'edit-con': $route.params.edit == 1 }">
          <div class="module-title prp-name margin-top-1em" style="padding-top: 1rem">
            Proposed Recommendations
          </div>
          <div class="recommend-d" style="display: flex">
            <div class="col-md-12" style="padding-left: 0px" v-if="$route.params.edit == 1">
              <label for="pillar" class="form-label select-lable lable-text">Change Type<span class="req">*</span></label>
              <select class="
                  units
                  form-control
                  page-heading-dropdown-content
                  principal-selecct
                  cust-st-icon
                " id="recommend" v-model="changeTypeMod" required>
                <option v-for="change in changeTypeList" v-bind:key="change.typeId" :value="change.typeId">
                  {{ change.typeDescription }}
                </option>
              </select>
            </div>
            <div class="col-md-12" style="padding-left: 0px" v-if="$route.params.edit == 0">
              <label for="pillar" class="form-label select-lable lable-text1">Change Type</label>
              <b-form-input class="form-control select-text select-text-pd text-class" id="changeType"
                :disabled="$route.params.edit == 0" style="background: transparent; border: 1px solid #313131">
              </b-form-input>
            </div>
            <div class="col-md-12" v-if="checkPersonaForEditProBtn">
              <label for="pillar" class="form-label select-lable lable-text1">Submitted By</label>
              <b-form-input class="form-control select-text select-text-pd text-class" id="submitBy" disabled
                v-model="submitBy" style="background: transparent; border: 1px solid #313131">
              </b-form-input>
            </div>
            <div class="col-md-12" v-if="checkPersonaBtn &&
              plants != '' &&
              plants != null &&
              plants != undefined
              ">
              <label for="pillar" class="form-label select-lable lable-text1">Plant</label>
              <b-form-input class="form-control select-text select-text-pd text-class" id="plant" disabled
                v-model="plants" style="background: #f9f9f9; border: 1px solid #313131">
              </b-form-input>
            </div>
          </div>
          <div class="col-lg-12 col-md-12 col-sm-12 padding-input">
            <label for="pillar" class="form-label select-lable"
              :class="$route.params.edit == 1 ? 'lable-text' : 'lable-text1'">Proposed Practice</label>
            <textarea class="form-control select-text text-col" :class="{ 'text-class': $route.params.edit == 0 }"
              id="proposedPractice" :disabled="$route.params.edit == 0" v-model="proposedPractice">
            </textarea>
          </div>
          <div class="col-lg-12 col-md-12 col-sm-12 padding-input">
            <label for="pillar" class="form-label select-lable"
              :class="$route.params.edit == 1 ? 'lable-text' : 'lable-text1'">Reason for change/Comments</label>
            <textarea class="form-control select-text text-col" :class="{ 'text-class': $route.params.edit == 0 }"
              id="reason" :disabled="$route.params.edit == 0" v-model="reasonForChange">
            </textarea>
          </div>
        </div>
        <div class="mat-info-con add-action-footer-info border-top" v-if="$route.params.edit == 0">
          <b-button pill class="primar-btn submit-btn" size="lg" @click="discardEdit()">
            Close
          </b-button>

        </div>
        <div class="mat-info-con add-action-footer-info border-top" v-else>
          <b-button class="primar-btn discard-btn" size="lg" @click="discardEdit()">
            Discard
          </b-button>
          <b-button v-b-modal.messagePopup pill class="primar-btn submit-btn" size="lg" @click="saveDetails()">
            Submit
          </b-button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import "../../../common/css/CommonStyle.css";
import "../../../common/css/font-categories.css";
import Breadcrumb from "../../../components/common/Breadcrumb.vue";
import Role from "../../../common/constants/roles";
import Api from "../../../common/constants/api";
import ApiCalls from "../../../common/api/apiCalls";
import Loader from "../../../components/common/Loader.vue";
import axios from 'axios';
export default {
  name: "SubmitRequest",
  components: {
    Breadcrumb,
    Loader,
  },
  data() {
    return {
      roles: Role,
      pillars: [],
      plants: "",
      principleNumber: 1,
      principleList: [],
      perPageSelf: 5,
      currentPageSelf: 1,
      checkConfirmation: false,
      perPageSec: 5,
      currentPageSec: 1,
      passingConfirmation: {},
      //levelId: 0,
      actionID: "",
      actionOwnerName: "",
      actionTargetDate: "",
      actionCompleteDate: "",
      actionDesc: "",
      showLoader: true,
      targetActions: "",
      committedActions: "",
      completedActions: "",
      secondPartyVisibility: true,
      selectedViewItem: null,
      fileErrorMessage: "",
      commentSelftAssessment: "",
      commentSecondAssessment: "",
      fileErrorMessageSecond: "",
      levelId: "",
      practiceCommittedDate: "",
      practiceCompletionDate: "",
      action_item: [],
      plantAssessmentStatusUpdate: "",
      plantAssessmentStatus: "",
      plantAssessmentCompleted: "",
      regionalAssessmentStatusUpdate: "",
      regionalAssessmentStatus: "",
      practiseDetailsData: [],
      attachmentUrls: [],
      practiceDescription: "",
      guidelineDesc: "",
      targetLevel: 0,
      practiseIdData: 0,
      principleId: 1,
      pillar: 1,
      practiceId: 1,
      selected: 0,
      completedSteps: 75,
      totalSteps: 100,
      plantId: "",
      pillarId: 1,
      principle_action_item: [],
      levelName: "",
      principleName: "",
      // principleId:'',
      submitBy: "",
      actionName: "",
      requestId: "",
      changeTypeList: [],
      changeTypeMod: "",
      proposedPractice: "",
      reasonForChange: "",
      edit: "",
      principleArr: [],
    };
  },
  beforeCreate() { },
  computed: {
    checkPersonaForEditProBtn() {
      return (this.$oidc.userProfile.roles.includes(this.roles.PLANTPOLEADER) || this.$oidc.userProfile.roles.includes(this.roles.PLANTPILLARLEADER) || this.$oidc.userProfile.roles.includes(this.roles.REGIONALPOLEADER) ||
        this.$oidc.userProfile.roles.includes(this.roles.REGIONALPILLARLEADER) || this.$oidc.userProfile.roles.includes(this.roles.PLANTMANAGER) || this.$oidc.userProfile.roles.includes(this.roles.GLOBALPILLARLEADER))
    },
    checkPersonaBtn() {
      return (this.$oidc.userProfile.roles.includes(this.roles.REGIONALPOLEADER) || this.$oidc.userProfile.roles.includes(this.roles.REGIONALPILLARLEADER) || this.$oidc.userProfile.roles.includes(this.roles.GLOBALPILLARLEADER))
    },
  },
  created() {
    this.edit = this.$route.params.edit;
    this.plantId = this.$route.params.plantId;
    this.targetLevel = this.$route.params.levelId;
    this.pillarName = this.$route.params.pillarName;
    this.principleId = this.$route.params.principleId;
    this.principleName = this.$route.params.principleName;
    this.principleArr = this.principleName.split(". ");

    this.requestId = this.$route.params.requestId;
    this.$store.dispatch("selectedMenu", {
      main: 2,
      subMenu: 4,
    });
    this.$store.dispatch("breadcrumb", {
      title: this.edit == 0 ? "View Request" : "Edit Request",
      breadcrumb: [
        { name: "Home / ", primary: true, redirect: "/dashboard" },
        {
          name: "Governance",
          redirect: "/governance",
          primary: true,
        },
        // { name: "Assessment", primary: false },
      ],
    });
    this.practiseDetails(this.$route.params.principleId);
    this.getPillarListData();
    this.getViewRequest();
  },
  methods: {
    async downloadItem(obj) {
      const url = JSON.parse(JSON.stringify(obj));
      const label = JSON.parse(JSON.stringify(decodeURIComponent(
        eachattachment
          .split("/")
        [eachattachment.split("/").length - 1].split("_")[0] +
        "." +
        eachattachment
          .split("/")
        [eachattachment.split("/").length - 1].split(".")[
        eachattachment
          .split("/")
        [eachattachment.split("/").length - 1].split(".")
          .length - 1
        ]
      )));
      const response = await axios.get(url, { responseType: "blob" });
      // apiCalls.httpCallDetails(url, "get").then((response) => {
      console.log(response);
      const blob = new Blob([response.data], { type: "application/pdf" });
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = label;
      link.click();
      URL.revokeObjectURL(link.href);
      // })

    },
    discardEdit() {
      this.$router.push("/governance");
    },
    getViewRequest() {
      ApiCalls.httpCallDetails(Api.GETVIEWREQUEST + this.requestId, "get").then(
        (data) => {
          if (this.$route.params.edit == 1) {
            this.changeTypeMod = data.data.changeType;
            this.proposedPractice = data.data.proposedPractice;
            this.reasonForChange = data.data.reasonForChange;
          } else {
            this.plants = data.data.plant;
            document.getElementById("proposedPractice").value = data.data
              .proposedPractice
              ? data.data.proposedPractice
              : "";
            document.getElementById("reason").value = data.data.reasonForChange
              ? data.data.reasonForChange
              : "";
            document.getElementById("changeType").value = data.data.changeType
              ? data.data.changeType
              : "";
            if (
              data.data.submittedBy !== "" &&
              data.data.submittedBy !== null &&
              data.data.submittedBy !== undefined
            ) {
              this.submitBy = data.data.submittedBy;
            }
            if (
              data.data.plant !== "" &&
              data.data.plant !== null &&
              data.data.plant !== undefined
            ) {
              this.plants = data.data.plant;
            }
            this.changeTypeMod = data.data.changeType;
            this.proposedPractice = data.data.proposedPractice;
            this.reasonForChange = data.data.reasonForChange;
          }
        }
      );
    },
    practiseDetails(selectObject) {
      this.showLoader = true;
      let value = selectObject;
      ApiCalls.httpCallDetails(
        Api.PRACTISEDETAILS + value + "/" + this.plantId,
        //Api.PRACTISEDETAILS + value + "/" + 1,
        "get"
      ).then((dataJSON) => {
        this.practiseDetailsData = dataJSON.data.practiceList;

        let index = this.principleList.findIndex(
          (val) => val.principleId == value
        );
        this.principleNumber =
          index !== -1 ? this.principleList[index].principleNumber : 1;
        setTimeout(() => {
          this.handleRadioChange(this.targetLevel);
          this.showLoader = false;
        }, 300);
      });
    },
    //   getPlantPillarDetails() {
    //   ApiCalls.httpCallDetails(
    //     //Api.PLANTPILLARDETAILS + 1,
    //   Api.PLANTPILLARDETAILS + this.plantId,
    //     "get"
    //   ).then((data) => {
    //     this.apiCount++;
    //     this.pillarDetails = data.data;
    //     if (this.apiCount == 2) {
    //       this.showLoader = false;
    //       this.apiCount = 0;
    //     }
    //   });
    // },
    handleRadioChange(id) {
      let radio = document.getElementById("Level" + this.targetLevel);
      radio.classList.add("live-level");
      //}
      for (let i = 0; i <= 5; i++) {
        let radio = document.getElementById("Level" + i);
        radio.classList.remove("active-level");
      }
      this.guidelineDesc =
        this.practiseDetailsData[parseInt(id)] &&
          this.practiseDetailsData[parseInt(id)].guideline
          ? this.practiseDetailsData[parseInt(id)].guideline
          : "";
      this.attachmentUrls =
        this.practiseDetailsData[parseInt(id)].attachmentUrls;
      this.practiceDescription =
        this.practiseDetailsData[parseInt(id)] &&
          this.practiseDetailsData[parseInt(id)].practiceDescription
          ? this.practiseDetailsData[parseInt(id)].practiceDescription
          : "";
      let radioElement = document.getElementById("Level" + id);
      radioElement.classList.add("active-level");
      this.levelId = this.practiseDetailsData[parseInt(id)].levelId;
      this.practiceId = this.practiseDetailsData[parseInt(id)].practiceId;
    },
    getPillarListData() {
      let url = Api.PILLARLIST + this.pillarId;

      ApiCalls.httpCallDetails(url, "get").then((data) => {
        this.pillars = data.data;
      });
      this.getPrincipleData();
    },
    // pillarChanged(){
    //    this.getPrincipleData();
    // },
    async getPrincipleData() {
      this.showLoader = true;
      ApiCalls.httpCallDetails(Api.PRINCIPLELIST + this.pillarId, "get").then(
        (dataJSON) => {
          this.principleList = [];
          let data = dataJSON.data;
          this.principleList = data;
          this.practiseDetails(this.principleId);
          if (this.$route.params.edit == 1) this.getchangetypeData();
          this.showLoader = false;
        }
      );
    },
    getchangetypeData() {
      ApiCalls.httpCallDetails(Api.GETCHANGETYPEDROPDOWN, "get").then(
        (data) => {
          this.changeTypeList = data.data.filter((item, i) => {
            if (item.typeId != 4) return item;
          });

          let type = this.changeTypeMod;
          let selectedType = this.changeTypeList.find(
            (el) => el.typeDescription == type
          );
          this.changeTypeMod = selectedType.typeId;
        }
      );
    },
    saveDetails() {
      let selectedType = this.changeTypeList.find(
        (el) => el.typeId == this.changeTypeMod
      );
      let requestPayload = {
        changeType: selectedType.typeDescription,
        proposedPractice: this.proposedPractice,
        reasonForChange: this.reasonForChange,
      };

      ApiCalls.httpCallDetails(
        Api.UPDATEPRACTICE + this.requestId,
        "put",
        requestPayload
      ).then((data) => {
        this.showLoader = false;

        if (data.success) {
          setTimeout(() => {
            this.$router.go(-1);
          }, 600);
        }
      });
    },
  },
};
</script>

<style scoped>
.li-doc-class {
  margin-bottom: 8px;
  color: #274ba7;
  font-family: "BarlowR", sans-serif;
  list-style: none;
  line-height: 1.9166666666666667rem;
  font-size: 1.1666666666666667rem;
}

.uploaded-items-li {
  margin: 5px 0;
  display: flex;
  align-items: center;
}

/* .uploaded-file-name {
  font: 1.166rem "BarlowM";
  color: #274ba7;
  margin-right: 16px;
} */
.cursorNotAllowed {
  cursor: not-allowed;
  opacity: 0.3;
}

.proposed-rec {
  background: #f9f9f9;
  margin-left: 20px;
  margin-right: 20px;
  border-radius: 0.5rem;
  margin-bottom: 10px;
}

.level-border {
  display: flex;
  border: 1px solid #dedbdb;
  /* padding-top: 1em;
  padding-bottom: 1em; */
  border-radius: 6px;
}

.add-action-footer-info {
  padding-top: 1rem;
  background: #fff;
  padding-left: 2em !important;
  padding-right: 2em !important;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: flex-end;
}

.discard-btn {
  color: #274ba7;
  background: transparent;
  border: transparent;
  font-family: "BarlowM", sans-serif;
  font-size: 1.166rem;
  margin-right: 2rem;
}

.discard-btn:hover {
  color: #274ba7 !important;
  background: transparent !important;
  border: transparent !important;
}

.submit-btn {
  height: 3.3rem;
  width: 7.166666666666666rem;
  font-family: "BarlowM", sans-serif;
  font-size: 1.166rem;
  background-color: #274ba7;
}

.submit-btn:focus {
  background-color: #274ba7;
}

.req {
  font-size: 1rem;
  color: red;
}

.text-col {
  height: 6rem;
}

.padding-input {
  /* padding-top: 2rem; */
  padding-left: 0rem;
  padding-bottom: 2rem;
}

.text-class {
  background-color: #f9f9f9 !important;
  border: 1px solid #d5d2d2;
  border: 1px solid #313131;
  color: #313131;
  /* padding-bottom: 2rem; */
}

textarea {
  resize: none;
}

.marginRight16Percentage {
  margin-right: 5%;
}

.col-5 {
  max-width: 55%;
}

.width100 {
  width: 100%;
}

.marginTop-minus-2 {
  margin-top: -2% !important;
}

.marginBottom-2 {
  margin-bottom: 2% !important;
}

.lable-text {
  position: absolute;
  /* background: rgb(243, 240, 240); */
  background: #fff;
  margin: -1.2rem 0 0 1rem;
  padding-right: 5px;
  padding-left: 5px;
}

.lable-text1 {
  position: absolute;
  /* background: rgb(243, 240, 240); */
  background: #f9f9f9;
  margin: -1.2rem 0 0 1rem;
  padding-right: 5px;
  padding-left: 5px;
}

.calender-input {
  height: 52px;
  width: 59.25rem;
  font-size: 1.166rem;
  font-family: "BarlowR", sans-serif;
}

@media only screen and (max-width: 1365px) {
  .calender-input {
    height: 52px;
    width: 39.25rem;
    font-size: 1.166rem;
    font-family: "BarlowR", sans-serif;
  }
}

@media only screen and (min-width: 1366px) and (max-width: 1380px) {
  .calender-input {
    height: 52px;
    width: 43.25rem;
    font-size: 1.166rem;
    font-family: "BarlowR", sans-serif;
  }
}

@media only screen and (max-width: 1680px) and (min-width: 1381px) {
  .calender-input {
    height: 52px;
    width: 55.25rem;
    font-size: 1.166rem;
    font-family: "BarlowR", sans-serif;
  }
}

@media only screen and (min-width: 1681px) and (max-width: 1920px) {
  .calender-input {
    height: 52px;
    width: 64.75rem;
    font-size: 1.166rem;
    font-family: "BarlowR", sans-serif;
  }
}

@media only screen and (min-width: 1921px) {
  .calender-input {
    height: 52px;
    width: 68.25rem;
    font-size: 1.166rem;
    font-family: "BarlowR", sans-serif;
  }
}

.other-block {
  /* display: grid;
  justify-content: end; */
  margin-top: 2rem;
}

div.fileinputs {
  position: relative;
  border: 1px solid #313131;
  border-radius: 4px;
}

div.fakefile {
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  width: 100%;
}

.fakefileinput {
  height: 100%;
  width: 80%;
  border: transparent;
  border-right: 1px solid #313131;
  color: #313131;
  font: 1.166rem "BarlowR", sans-serif;
  padding: 5px;
  margin: 2px;
}

.fakefilespan {
  text-align: center;
  width: 20%;
  font: 1.166rem "BarlowSb", sans-serif;
  color: #274ba7;
}

input.file {
  position: relative;
  text-align: right;
  /* -moz-opacity:0 ;
	filter:alpha(opacity: 0); */
  opacity: 0;
  z-index: 2;
}

.container {
  position: relative;
  cursor: pointer;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Hide the default radio button */
.container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

#editButton:disabled {
  cursor: not-allowed;
  opacity: 0.3;
}

.top-card {
  width: 7rem;
}

.plant-card {
  background: #fffce3;
  /* // height: 54px; */
  margin-left: 1.5em;
  text-align: center;
  color: #414141;
  padding: 8px;
  border-radius: 7px;
}

.action_label {
  font-family: "BarlowM", sans-serif;
  line-height: 1.5rem;
  font-size: 1.3333333333333333rem;
}

.btn-action {
  font-family: "BarlowM", sans-serif;
  line-height: 1.6666666666666667rem;
  font-size: 1rem;
}

.no-data {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-content: center;
  align-items: center;
  height: 8rem;
  justify-content: space-evenly;
}

.progress-con {
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
}

.per-text {
  color: #0f7222;
  font-weight: 600;
}

.asmt-con {
  background: #fff;
  border-radius: 6px;
  margin-top: 1em;
  padding-bottom: 1em;
}

.asmt-info {
  border-bottom: 1px solid #dedbdb;
  padding-left: 2em !important;
  padding-right: 2em !important;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.asmt-padding {
  margin-top: 1em;
  margin-bottom: 1em;
}

.asmt-footer-info {
  border-top: 1px solid #dedbdb;
  padding-left: 2em !important;
  padding-right: 2em !important;
  display: flex;
  width: 100%;
  align-items: center;
}

.principle-dd {
  width: 30%;
  padding: 2rem 2rem;
  padding-bottom: 0;
}

.recommend-d {
  width: 30%;
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.principal-selecct {
  height: 54px;
  border-color: #313131;
}

.level-con {
  padding: 1em;
  margin-top: 1em;
  padding-left: 2em;
  padding-right: 2em;
  cursor: pointer;
}

.level-info {
  width: 17%;
  text-align: center;
  height: 64px;
  vertical-align: middle;
  display: flex;
  align-items: center;
  justify-content: center;
}

.live-level {
  background: #eff3ff;
}

.cursorpointer {
  cursor: pointer;
}

.level-br {
  border-right: 1px solid #dedbdb;
}

.active-level {
  background: #00428f;
  /* background: #006400;6577AC */
  color: #fff;
}

.active-level:after {
  content: "";
  position: relative;
  top: 58%;
  left: -45%;
  /* margin-left: -50px; */
  width: 0;
  height: 0;
  border-top: solid 10px #00428f;
  border-left: solid 10px transparent;
  border-right: solid 10px transparent;
}

.prp-info {
  padding-left: 2em;
  padding-right: 2em;
}

.prp-name {
  margin-top: 3px;
}

.margin-top-1em {
  margin-top: 1rem;
}

.behavoir-des {
  margin-top: 3px;
}

.attch-con {
  padding-left: 2em;
  padding-right: 2em;
}

.attch-col {
  border-radius: 6px;
  padding-top: 10px !important;
  padding-bottom: 10px !important;
  padding-left: 5px !important;
  color: #274ba7;
  list-style: none;
}

.attch-row {
  padding-left: 1em;
  padding-right: 2em;
  margin-top: 5px;
}

.attch-icon {
  float: right;
}

.review-con {
  padding-left: 2em;
  padding-right: 2em;
}

.review-rox {
  margin-left: 0px !important;
  margin-right: 0px !important;
  margin-top: 10px;
}

.review-col {
  border: 2px solid #e2dfdf;
  border-radius: 6px;
  padding: 1em;
}

.pm-rw-con {
  margin-right: 71px;
}

.review-row {
  padding: 1em;
}

.review-type-col {
  text-align: center;
  background: #f0f7ff;
  border-radius: 6px;
  padding: 1em;
  margin-right: 10px;
}

.rw-act-con {
  text-align: right;
  margin-top: 10px;
}

.cancel-btn {
  border: 0px !important;
}

.cancel-btn:hover {
  background: #fff;
  color: #274ba7;
}


.cancel-btn:active:focus {
  box-shadow: 0 0 0 0 rgb(108 117 125 / 50%) !important;
}

.save-btn {
  border-color: #869ed8 !important;
  background: #eff3ff 0% 0% no-repeat padding-box !important;
  color: #274ba7 !important;
}

.save-btn:hover {
  border-color: #869ed8 !important;
  color: #274ba7 !important;
  background: #cad0e4 0% 0% no-repeat padding-box !important;
}

.cancel-btn:active {
  border-color: #869ed8 !important;
  color: #274ba7 !important;
  background: #cad0e4 0% 0% no-repeat padding-box !important;
}

.save-btn:active:focus {
  box-shadow: 0 0 0 0 rgb(108 117 125 / 50%) !important;
}

.shortHeight {
  height: 12rem;
}

.pagination-con {
  font-size: 0.875em;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  padding: 0;
  align-items: center;
  margin: 0;
}

@media only screen and (max-width: 1100px) {
  .active-level:after {
    left: -27% !important;
  }
}

@media only screen and (max-width: 875px) {
  .active-level:after {
    left: -30% !important;
  }
}

@media only screen and (max-width: 575px) {
  .level-con {
    overflow-x: auto;
  }

  .level-info {
    padding: 1em;
    width: 41%;
  }

  .principle-dd {
    width: 50%;
  }

  .active-level:after {
    left: -40% !important;
  }
}

@media only screen and (min-width: 768px) {
  active-level:after {
    top: 71.5%;
    left: 61%;
    margin-left: -45px;
  }

  .review-type-col {
    max-width: 30.333333% !important;
  }

  .review-rox .col-6 {
    flex: 0 0 100% !important;
    max-width: 100% !important;
  }
}

@media only screen and (max-width: 768px) {
  .review-rox .col-6 {
    flex: 0 0 100% !important;
    max-width: 100% !important;
  }

  .asmt-info {
    display: block !important;
  }

  .asmt-name {
    width: 100% !important;
  }

  .progress-con {
    width: 100% !important;
  }
}

@media only screen and (max-width: 1342px) {
  .pm-rw-con {
    margin-right: 35px !important;
  }
}

@media only screen and (max-width: 1342px) {
  .pm-rw-con {
    margin-right: 35px !important;
  }
}

@media only screen and (min-width: 992px) {
  .review-rox .col-6 {
    max-width: 47% !important;
  }
}

@media only screen and (max-width: 992px) {
  .pp-rw {
    margin-top: 1em !important;
  }

  .principle-dd {
    width: 40% !important;
  }

  .pagination-align {
    justify-content: left !important;
  }
}

@media only screen and (max-width: 575px) {
  .col-4 {
    flex: 0 0 100% !important;
    max-width: 100% !important;
    margin-top: 10px !important;
  }

  .col-3 {
    flex: 0 0 100% !important;
    max-width: 100% !important;
  }

  .principle-dd {
    width: 100% !important;
  }
}

.review-box:focus~label {
  color: #274ba7;
  top: -0.8rem;
  left: 1rem;
  transition: all 0.125s ease;
  background: #fff;
  padding: 0 4px;
}

.review-box:focus {
  border: 2px solid #274ba7;
}

.review-box:focus-visible {
  border: 2px solid #274ba7;
}

.styled-input {
  width: 33.3333%;
  position: relative;
}

.styled-input label {
  padding: 1.6rem;
  position: absolute;
  top: 0;
  left: 0;
  -webkit-transition: all 0.25s ease;
  transition: all 0.25s ease;
  pointer-events: none;
}

.styled-input.wide {
  width: 100%;
}



.review-box:focus~span {
  width: 100%;
  -webkit-transition: all 0.075s ease;
  transition: all 0.075s ease;
}


.review-box {
  width: 100%;
  border: 1px solid #d2cbcb;
  border-radius: 6px;
  height: 5em;
  resize: none;
  padding: 1rem 1rem;
  font-size: 1rem;
  font-family: "BarlowR", sans-serif;
}


.review-com {
  margin-top: 10px;
}

.add-action {
  text-align: right;
}

.action-item-con {
  padding-left: 2em;
  padding-right: 2em;
  margin-top: 2em;
}

.back-btn {
  background: #274ba7;
  border-color: #274ba7;
  padding: 0.6rem 1.2rem;
}

.back-btn :hover {
  border-color: #6e92ec !important;
  color: #fff !important;
  background: #6e92ec 0% 0% no-repeat padding-box !important;
}

.back-btn :active {
  border-color: #6e92ec !important;
  color: #fff !important;
  background: #6e92ec 0% 0% no-repeat padding-box !important;
}

.add-action-btn {
  background: #274ba7;
  border-color: #274ba7;
}

.add-action-btn:hover {
  border-color: #6e92ec !important;
  color: #fff !important;
  background: #6e92ec 0% 0% no-repeat padding-box !important;
}

.add-action-btn:active {
  border-color: #6e92ec !important;
  color: #fff !important;
  background: #6e92ec 0% 0% no-repeat padding-box !important;
}

.add-action:active:focus {
  box-shadow: 0 0 0 0 rgb(108 117 125 / 50%) !important;
}

.act-tal-con {
  margin-top: 1em;
}

.back-btn-con {
  text-align: right;
  margin-right: 15px;
}

.take-assessment-page-heading-tab-bottom-padding {
  padding-bottom: 1rem;
}

.take-assessment-page-heading-tab-font-weight {
  font-size: 1.083rem;
  font-family: "BarlowM", sans-serif;
}

.status-con {
  display: flex;
}

.status-col-1 {
  width: 50%;
}

.status-col-2 {
  width: 50%;
  text-align: right;
}

.status-btn {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.status-btn-2 {
  margin-left: 1rem;
}

.file-con {
  width: 100%;
}

#errorForComment,
#errorForUploadFile {
  color: red;
  font-family: "BarlowR", sans-serif;
}

#edit-li:disabled {
  cursor: not-allowed;
  opacity: 0.3;
}

#edit-li {
  width: 100%;
  text-align: left;
  border: none;
  background: none;
}

.backBtnBlock {
  display: flex;
  justify-content: flex-end;
}

@media only screen and (min-width: 992px) and (max-width: 1240px) {
  .status-con[data-v-0d8de0d6] {
    display: block !important;
  }

  .status-col-2[data-v-0d8de0d6] {
    width: 100%;
    text-align: left;
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
}

@media only screen and (max-width: 665px) {
  .status-con[data-v-0d8de0d6] {
    display: block !important;
  }

  .status-col-2[data-v-0d8de0d6] {
    width: 100%;
    text-align: left;
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
}

@media only screen and (min-width: 320px) and (max-width: 388px) {
  .status-btn-2 {
    margin-left: 0rem;
    margin-top: 1rem !important;
  }
}

.comment-lable {
  color: #274ba7;
  top: -0.8rem !important;
  left: 1rem !important;
  background: #fff;
  padding: 0 4px !important;
}

.edit-con {
  padding-left: 0px;
  background: #fff;
}
</style>